<template>
	<div class="collapse">

		<b-collapse
            class="card"
            animation="slide"
            :open="isOpen"
            @open="clickHandler"
			@close="clickHandler"
            :aria-id="'contentIdForA11y5-' + _uid">
            <template #trigger="props">
                <div class="card-header" role="button"
                    :aria-controls="'contentIdForA11y5-' + _uid"
                    :aria-expanded="props.open">
					<div class="card-header__content">
						<slot name="header-before"></slot>
						<div class="card-header-content__title">
							{{ title }}
						</div>
						<slot name="header-after"></slot>
					</div>
					<div class="card-header__button" >
						<div class="open-collapse" v-if="$slots.default || $slots.tools">
							<img v-if="props.open" src="@/assets/keyboard-arrow-up-rounded.svg" height="24" width="24">
							<img v-else src="@/assets/keyboard-arrow-down-rounded.svg" height="24" width="24">
						</div>
						<div class="open-details" v-else>
							<img src="@/assets/keyboard-arrow-up-rounded.svg" height="24" width="24">
						</div>
					</div>
                </div>
            </template>
			<div class="card-content" v-if="$slots.default">
				<slot></slot>
			</div>
			<footer class="card-footer" v-if="$slots.tools">
				<slot name="tools"></slot>
			</footer>
        </b-collapse>
	</div>
</template>

<script>
import {addOpacityToColor} from '../../scripts/color'

export default {
	name: 'CollapseCard',

	emits: [
		'on-button-click',
	],

	props: {
		title: {
			type: String,
			required: true,
		}
	},

	data() {
		return {
			isOpen:false,
		};
	},

	methods: {
		addOpacityToColor: addOpacityToColor,
		toggleCollapse(){
			this.isOpen = !this.isOpen
		},
		clickHandler(event){
			if (this.$slots.default || this.$slots.tools) {
				this.toggleCollapse()
			}

			this.$emit('on-button-click', event)
		}
	},
};
</script>

<style scoped>
.collapse{
	animation: fade .3s;
}

.collapse__header{
	padding: 7px 6px 5px 6px;
	display: flex;
	flex-direction: row;
	gap: 16px;
}


.collapse-header__title{
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.collapse-header__icons{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.collapse-header__toggle-button{
	display: flex;
	align-items: center;
}

.collapse-header__toggle-button.opened{
	transform: rotate(180deg);
}

.collapse__collapse{
	max-height: 144px;
}

.content-enter-active{
	animation: content-anim .3s;
}

.content-leave-active{
	animation: content-anim .3s reverse ;
}

@keyframes content-anim {
	from {
		max-height: 0;
	}
	to {
		max-height: 144px;
	}	
}

.collapse-header__score{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.score__item{
	height: 19px;
	width: 19px;
	border-radius: 12px;
	background: #0075ff;
}

.score__count{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 19px;
}

.collapse__content{
	height: 100%;
	padding: 10px;
}

.collapse__tool-bar{
	height: 32px;
}

.card-header{
	display: flex;
	padding: 12px 16px;
	gap: 16px;
}

.card-header__button > *{
	display: flex;
	justify-content: center;
	align-items: center;
	width:24px;
	justify-content: center;
}

.card-header__button > .open-details > img {
	transform: rotate(90deg);
}

.card-header__content{
	width: 100%;
	display: flex;
	gap: 10px;
	max-width: calc(100% - 16px - 24px);
}

.card-header-content__title{
	flex: 1;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-left: 0;
}
</style>