<template>
	<div class="rating-menu">
		<div class="rating-menu__label" v-if="changeIsOpen">
			<div class="label__text">
				<b-input v-model="newRatingTypeName" placeholder="Enter rating type" ref="changeTypeName"></b-input>
			</div>
			<div class="label__change">
				<b-button @click="submitChange" type="is-info" size="is-small">Save</b-button>
				<b-button @click="closeChange" type="is-info is-light" size="is-small">Cancel</b-button>
			</div>
		</div>
		<div class="rating-menu__label" v-else>
			<div class="label__text">{{ratingTypeName}}</div>
			<div class="label__change">
				<b-button @click="openChange" type="is-info is-light" size="is-small">Change</b-button>
			</div>
		</div>
		<div class="rating-menu__rating-list">
			<CollapseCard 
			v-for="rating in ratings"
			:key="`rating_${rating.id}`" 
			:title="rating.name">

				<template v-slot:header-before>
					<b-tag type="is-info">
						{{ rating.val }}
					</b-tag>
				</template>

				<template v-slot:tools>
					<div @click="openEditHandler(rating)" class="card-footer-item">Edit</div>
					<div @click="deleteHandler(rating.id)" class="card-footer-item">Delete</div>
				</template>				

			</CollapseCard>
		</div>
		<div class="rating-menu__create-form" key="1" v-if="createIsOpen || ratingToEdit">
			
			<div class="create-form__fields">
				<b-input v-model="createRatingName" 
					:placeholder="`Enter ${ratingTypeName.toLowerCase()} rating name`"
					ref="createTypeName">
				</b-input>
				<b-input v-model="createRatingValue" placeholder="0" type="number" min="0" max="100">
				</b-input>
			</div>

			<div class="buttons">
				<b-button type="is-info" @click="submit">Save</b-button>
				<b-button type="is-info is-light" @click="closeCreate">Cancel</b-button>
			</div>
		</div>
		<div class="rating-menu__create-form" v-else>
			<b-button type="is-info" @click="openCreate" outlined>Add</b-button>
		</div>
		
	</div>
</template>

<script>
import CMS from '../../service/cms/service';
import CollapseCard from "../inputs/CollapseCard.vue"

export default {
	name: 'RatingMenu',

	components: {
		CollapseCard,
	},

	computed:{
		ratings(){
			return this.$store.getters.calendarRatings
		},
		calendar(){
			return this.$store.getters.currentCalendar()
		},
		ratingTypeName(){
			if (!this.calendar)
				return undefined
			return this.calendar.settings.ratingTypeName ?? "Complexity"
		}
	},

	// created() {
	// 	if (!this.ratings || this.ratings.length == 0){
	// 		this.$store.dispatch('setup-slot-templates')
	// 	}
	// },

	data(){
		return {
			newRatingTypeName: "",
			changeIsOpen: false,
			createIsOpen: false,
			ratingToEdit: null,
			createRatingName: "",
			createRatingValue: 0,
		}
	}, 

	methods:{
		openChange(){
			this.newRatingTypeName = this.ratingTypeName
			this.changeIsOpen = true
			this.$nextTick(() => {
				this.$refs.changeTypeName.focus()
			})
		},
		closeChange(){
			this.changeIsOpen = false
		},
		submitChange(){
			let calendarUid = this.$route.params.calendarUid
			let submitData = {
				name: this.newRatingTypeName,
			}
			CMS.rating.setTypeName(calendarUid, submitData).then((newRatingData) => {
				if (newRatingData.err){
					throw newRatingData.err
				}
				
				this.$set(this.calendar.settings, 'ratingTypeName', this.newRatingTypeName)
				this.closeChange()
			})	
		},
		openCreate(){
			this.createIsOpen = true
			this.$nextTick(() => {
				this.$refs.createTypeName.focus()
			})
		},
		closeCreate(){
			this.ratingToEdit = null
			this.createRatingName = ""
			this.createRatingValue = 0
			this.createIsOpen = false
		},
		submitCreate(){
			if (this.createRatingName == "" || Number(this.createRatingValue) == 0) {
				return
			}

			let calendarUid = this.$route.params.calendarUid
			let submitData = {
				name: this.createRatingName,
				val: Number(this.createRatingValue),
			}
			CMS.rating.create(calendarUid, submitData).then((newRatingData) => {
				if (newRatingData.err){
					throw newRatingData.err
				}
				this.$store.dispatch('add-rating-item', newRatingData)
				this.createRatingName = ""
				this.createRatingValue = 0
				this.closeCreate()
			})		
		},
		newRatingValueValidator(newValue){
			if (!newValue) return ""
			return newValue > 0 ? newValue : 0
		},

		deleteHandler(ratingId){
			let calendarUid = this.$route.params.calendarUid
			CMS.rating.delete(calendarUid, ratingId).then((data) => {
				if (data.err){
					throw data.err
				}

				this.$store.dispatch('del-rating-item', ratingId)
			})	
		},
		openEditHandler(rating){
			this.ratingToEdit = rating.id
			this.createRatingName = rating.name
			this.createRatingValue = rating.val
			this.$nextTick(() => {
				this.$refs.createTypeName.focus()
			})
		},

		submit(){
			this.ratingToEdit ? this.submitEdit() : this.submitCreate()
		},	

		submitEdit(){
			let calendarUid = this.$route.params.calendarUid
			if (this.createRatingName == "" || Number(this.createRatingValue) == 0) {
				return
			}

			let submitData = {
				name: this.createRatingName,
				val: Number(this.createRatingValue),
			}
			console.log('this.ratingToEdit :>> ', this.ratingToEdit);
			CMS.rating.update(calendarUid, this.ratingToEdit, submitData).then((newRatingData) => {
				if (newRatingData.err){
					throw newRatingData.err
				}
				this.$store.dispatch('upd-rating-item', newRatingData)
				this.createRatingName = ""
				this.createRatingValue = 0
				this.ratingToEdit = null
				this.createIsOpen = false
			})	
		}
	}
};
</script>

<style scoped>
.rating-menu{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.rating-menu__rating-list{
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.rating-menu__label{
	display: flex;
	flex-direction: row;
	gap: 16px;
	height: 34px;
	align-items: center;
}

.label__text{
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	height: fit-content;
	font-size: 18px;
}

.label__change{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.rating-menu__create-form{
	display: flex;
	flex-direction: column;
	gap: 16px;
	animation: fade .3s;
}

.create-form__fields{
	display: grid;
	grid-template-columns: calc(80% - 16px) 20%;
	column-gap: 16px;
}

.create-form__buttons{
	display: flex;
	gap: 16px;
	width: 100%;
}


.create-enter-active{
	animation: create .25s;	
}
.create-leave-active{
	animation: create .25s reverse;
}

@keyframes create {
	from {opacity: 0;}
	to {opacity: 1;}
}

.collapse-tools{
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	height: 44px;
	padding: 6px 0;
}

</style>